import './index.css';
import 'react-image-lightbox/style.css';
import Lightbox from 'react-image-lightbox';
import React from 'react';
import ReactDOM from 'react-dom';
import Search from './search';
import Sidebar from './sidebar';
import galleryData from './assetsDB.json';
import lunr from 'lunr';
import preloadedIndex from './searchCache.json';
import { LazyLoadImage } from 'react-lazy-load-image-component';
const idx = lunr.Index.load(preloadedIndex);
console.log(__dirname);
class Gallery extends React.Component {
    constructor (props) {
        super(props);
        this.searchHandler = this.searchHandler.bind(this);
        this.sidebarHandler = this.sidebarHandler.bind(this);
        this.state = {
            results: [],
            lightboxImg: '',
            started: false,
            isOpen: false,
            search: '',
            all: true,
            female: true,
            male: true,
            accs: false,
            berd: false,
            decl: false,
            feet: false,
            hair: false,
            hand: false,
            head: false,
            jbib: false,
            lowr: false,
            task: false,
            teef: false,
            uppr: false
        };
    }

    componentDidMount () {
        //We set the default states already and on react components first rendering we create the startup search
        this.createSearch(true); // Generate the search for the startup default settings (Male, Female & All)
    }

    createSearch (startup) {
        var ignoredGroups = [];
        var searchString = ''; //These being separate used to matter more but I have improved the system since and just am too lazy to change this as it works fine as is
        var stringAmendment = '';
        var i;
        if (this.state.all !== true && startup !== true) {
            for (i in this.state) {
                if (i !== 'isOpen' && i !== 'lightboxImg' && i !== 'started') {
                    // Since we are iterating over the entire set of react states we have a few we don't care to process for our search string that aren't groups

                    if (this.state[i] === false) {
                        // If a checkbox isn't selected then add it to the array that will be used to populate the search string for things we don't want in our results.
                        if (i === 'female' || i === 'male') {
                            //If one of the genders isn't wanted then send that as well, these are handled differently because they need to be lowercase for reasons I still don't remember, doesn't matter.
                            ignoredGroups.push(i);
                        } else {
                            //Groups need to be uppercase to match the data in the search json IIRC, genders do not, there's some reason for this I just have forgotten it over time lol.
                            ignoredGroups.push(i.toUpperCase());
                        }
                    }

                    if (i === 'search') {
                        // Setup search string
                        searchString = '*' + this.state[i] + '*'; //Search string could be anywhere in the middle of a model name so we wildcard the beginning and end for the search
                    }
                }
            }

            for (i in ignoredGroups) {
                if (ignoredGroups[i] === 'female' || ignoredGroups[i] === 'male') {
                    stringAmendment = stringAmendment + ' -gender:' + ignoredGroups[i]; //If there are any genders you don't have checkmarked add them to not be searched here
                } else {
                    stringAmendment = stringAmendment + ' -group:' + ignoredGroups[i]; //Any groups that wish to be excluded from the searching
                }
            }
        } else {
            //Either the user has selected the "All" option or it's the script startup
            stringAmendment = ''; //Clear string amendment incase we had male/female unchecked previously and it had them in the "don't saerch" settings
            searchString = '*' + this.state.search + '*'; //Search string could be anywhere in the middle of a model name so we wildcard the beginning and end for the search

            if (startup === false) {
                if (this.state.female === false) {
                    stringAmendment = stringAmendment + ' -gender:female';
                }
                if (this.state.male === false) {
                    stringAmendment = stringAmendment + ' -gender:male';
                }
            }
        }

        var search = searchString + stringAmendment;
        const searchData = idx.search(search.toString());
        var searchResults = [];
        if (searchData.length > 0) {
            for (i in searchData) {
                var url =
          'public/clothes/' +
          galleryData.images[searchData[i].ref].gender +
          '/' +
          galleryData.images[searchData[i].ref].group +
          '/' +
          galleryData.images[searchData[i].ref].image;
                searchResults.push({
                    key: Number(i),
                    src: url,
                    thumbnail:
            'public/thumbnails/' +
            galleryData.images[searchData[i].ref].image,
                    thumbnailWidth: 250,
                    thumbnailHeight: 147,
                    modelName: galleryData.images[searchData[i].ref].image.slice(0, -4)
                });
            }
        }
        this.setState({ results: searchResults });
    }

    renderResults (imageUrl) {
        return (
            <div key={imageUrl.key}>
                <div className="card">
                    <LazyLoadImage
                        key={imageUrl.key}
                        height={imageUrl.thumbnailHeight}
                        width={imageUrl.thumbnailWidth}
                        src={imageUrl.thumbnail}
                        onClick={() => this.lightboxHandler(imageUrl.src)}
                    />
                    <a>{imageUrl.modelName}</a>
                </div>
            </div>
        );
    }

    lightboxHandler (imgsrc) {
        // Set the src of the image we want to display in the lightbox and toggle the lightbox open state
        this.setState({ lightboxImg: imgsrc });
        this.setState({ isOpen: !this.state.isOpen });
    }

    sidebarHandler (data) {
        this.setState(
            {
                [data.key]: data.toggle
            },
            function () {
                this.createSearch(false); // Create a scan of the checkbox configs once you have set some variables through the sidebar
            }
        );
    }

    searchHandler (data) {
        this.setState(
            {
                search: data
            },
            function () {
                this.createSearch(false); // Create a scan of the search configs once you have set some variables through the searchbox
            }
        );
    }

    modalHandler (open) {
        if (open) {
            return (
                <Lightbox
                    mainSrc={this.state.lightboxImg}
                    onCloseRequest={() => this.setState({ isOpen: false })}
                    enableZoom={false}
                />
            );
        }
    }

    render () {
        return (
            <div className="App">
                <Sidebar action={this.sidebarHandler} />
                <Search action={this.searchHandler} />
                <div className="gallery">
                    {this.state.results.map(imageUrl => this.renderResults(imageUrl))}
                </div>
                {this.modalHandler(this.state.isOpen)}
            </div>
        );
    }
}

ReactDOM.render(<Gallery />, document.getElementById('root'));
