import React from 'react';

class Sidebar extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            isAll: true,
            isFemale: true,
            isMale: true,
            isAccs: false,
            isBerd: false,
            isDecl: false,
            isFeet: false,
            isHair: false,
            isHand: false,
            isHead: false,
            isJbib: false,
            isLowr: false,
            isTask: false,
            isTeef: false,
            isUppr: false
        };
        this.toggleAll = this.toggleAll.bind(this);
        this.toggle = this.toggle.bind(this);
    }
    toggleAll () {
    // I could integrate this with toggle like the rest but honestly it doesn't really matter...
        for (var i in this.state) {
            if (i !== 'isAll' && i !== 'isFemale' && i !== 'isMale') {
                this.setState({ [i]: false });
            }
        }
        this.props.action({ key: 'all', toggle: !this.state.isAll });
        this.setState({ isAll: !this.state.isAll });
    }
    toggle (key, stateTarget) {
        if (this.state.isAll === true && key !== 'female' && key !== 'male') {
            // Don't turn All off if you are simply switching between genders not groups
            this.toggleAll();
        }
        this.props.action({ key: key, toggle: !this.state[stateTarget] });
        this.setState({ [stateTarget]: !this.state[stateTarget] });
    }

    render () {
        return (
            <div className="sidebar">
                <div className="genders">
                    <a className="App item">Genders</a>
                    <label>
                        <input
                            className="checkboxes"
                            checked={this.state.isMale}
                            type="checkbox"
                            onChange={() => this.toggle('male', 'isMale')}
                        />
            Male
                    </label>
                    <br />
                    <label>
                        <input
                            className="checkboxes"
                            checked={this.state.isFemale}
                            type="checkbox"
                            onChange={() => this.toggle('female', 'isFemale')}
                        />
            Female
                    </label>
                    <hr />
                    <a className="App item">Groups</a>
                    <label>
                        <input
                            className="checkboxes"
                            checked={this.state.isAll}
                            type="checkbox"
                            onChange={this.toggleAll}
                        />
            ALL
                    </label>
                    <br />
                    <label>
                        <input
                            className="checkboxes"
                            type="checkbox"
                            checked={this.state.isAccs}
                            onChange={() => this.toggle('accs', 'isAccs')}
                        />
            ACCS
                    </label>
                    <br />
                    <label>
                        <input
                            className="checkboxes"
                            type="checkbox"
                            checked={this.state.isBerd}
                            onChange={() => this.toggle('berd', 'isBerd')}
                        />
            BERD
                    </label>
                    <br />
                    <label>
                        <input
                            className="checkboxes"
                            type="checkbox"
                            checked={this.state.isDecl}
                            onChange={() => this.toggle('decl', 'isDecl')}
                        />
            DECL
                    </label>
                    <br />
                    <label>
                        <input
                            className="checkboxes"
                            type="checkbox"
                            checked={this.state.isFeet}
                            onChange={() => this.toggle('feet', 'isFeet')}
                        />
            FEET
                    </label>
                    <br />
                    <label>
                        <input
                            className="checkboxes"
                            type="checkbox"
                            checked={this.state.isHair}
                            onChange={() => this.toggle('hair', 'isHair')}
                        />
            HAIR
                    </label>
                    <br />
                    <label>
                        <input
                            className="checkboxes"
                            type="checkbox"
                            checked={this.state.isHand}
                            onChange={() => this.toggle('hand', 'isHand')}
                        />
            HAND
                    </label>
                    <br />
                    <label>
                        <input
                            className="checkboxes"
                            type="checkbox"
                            checked={this.state.isHead}
                            onChange={() => this.toggle('head', 'isHead')}
                        />
            HEAD
                    </label>
                    <br />
                    <label>
                        <input
                            className="checkboxes"
                            type="checkbox"
                            checked={this.state.isJbib}
                            onChange={() => this.toggle('jbib', 'isJbib')}
                        />
            JBIB
                    </label>
                    <br />
                    <label>
                        <input
                            className="checkboxes"
                            type="checkbox"
                            checked={this.state.isLowr}
                            onChange={() => this.toggle('lowr', 'isLowr')}
                        />
            LOWR
                    </label>
                    <br />
                    <label>
                        <input
                            className="checkboxes"
                            type="checkbox"
                            checked={this.state.isTask}
                            onChange={() => this.toggle('task', 'isTask')}
                        />
            TASK
                    </label>
                    <br />
                    <label>
                        <input
                            className="checkboxes"
                            type="checkbox"
                            checked={this.state.isTeef}
                            onChange={() => this.toggle('teef', 'isTeef')}
                        />
            TEEF
                    </label>
                    <br />
                    <label>
                        <input
                            className="checkboxes"
                            type="checkbox"
                            checked={this.state.isUppr}
                            onChange={() => this.toggle('uppr', 'isUppr')}
                        />
            UPPR
                    </label>
                    <br />
                </div>
            </div>
        );
    }
}

export default Sidebar;
