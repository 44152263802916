import React from 'react';

class Search extends React.Component {
    constructor (props) {
        super(props);
        this.state = { value: '', timeout: 0 };
        this.searchHandler = this.searchHandler.bind(this);
    }

    searchHandler (event) {
        var searchText = event.target.value;
        this.setState({ value: event.target.value });
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
            this.props.action(searchText);
        }, 500); //The delay in MS between a key press and the saerch result being sent out (Prevents each keystroke from doing a search and waits a moment before actually sending the search after your last keystroke, makes things smoother)
    }

    render () {
        return (
            <input
                autoComplete="off"
                className="search"
                type="text"
                name="search"
                placeholder="Search"
                value={this.state.value}
                onChange={this.searchHandler}
            />
        );
    }
}

export default Search;
